import React from 'react';
import './css/normalize.css';
import './css/global.css';
import HttpsRedirect from 'react-https-redirect';
import {initializeApp } from "firebase/app";
import { getFirestore, addDoc, setDoc, getDoc, doc, deleteDoc, collection } from "firebase/firestore";

import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "@firebase/auth";
import image from "../assets/vikit_logo.png";
import googleLogo from "../assets/Google__G__logo.svg.png";
import axios from 'axios';



var errorDisplayed=""


const provider = new GoogleAuthProvider();



const PlatformDisconnected = ({fireBaseApp, auth, db, setConnected}) => (
  <HttpsRedirect><div>
  <body class="antialiased p-6">
    {/*<nav class="flex items-center justify-between flex-wrap">
      <div>
        <button
          class="bg-white hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-pasha focus:bg-white focus:text-pasha font-light py-2 px-4 rounded"
          type="button"
        >
          Sign in
        </button>
      </div>
    </nav>*/}
    <div class="flex justify-center">
      <div class="w-full max-w-sm m-6">
        <div class="w-1/3 flex items-center mb-8">
          <img
            class="flex-1 w-24"
            src={image}
          />
        </div>
        <div class="text-pasha font-semibold text-xl mb-4">
          Vikit.ai creative AI models warehouse
        </div>
{/*
        <form id="signup-form" >
          <div class="w-full mb-2">
            <input
              class="appearance-none border-2 border-gray-200 rounded-md w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pasha"
              id="email"
              type="text"
              placeholder="Email address"
              required
              
            />
          </div>

          <button
            id="email-submit"
            class="w-full bg-pasha hover:bg-red-200 hover:shadow-outline rounded-md hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded"
            type="submit"
            onClick={() => signInWithPopup(auth, provider)
              .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
              }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
              })}
          >
            <div id="loading" class="hidden">Signing up...</div>
            <span id="button-text">Google sign in</span>
          </button>
        </form>*/
}
<br></br>
        <button
            id="email-submit"
            class="w-full bg-pasha hover:bg-red-200 hover:shadow-outline rounded-md hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded"
            type="submit"
            onClick={() => signInWithPopup(auth, provider)
              .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                console.log(result.user.uid)
                console.log(result.user.displayName)
                console.log(result.user.email)
                console.log(token)
                const docRef = doc(db, 'users', result.user.uid);
                console.log(docRef)
                getDoc(docRef).then(docSnap => {
                  console.log(docRef)
                if (!docSnap.exists()){
                setDoc(docRef, {
                  name: result.user.displayName,
                  email: result.user.email,
                  stripeId: null,
                  vikitAPIKey: null,
                  GPUA100Consumption: 0,
                }).then(() => {
                  auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                    console.log("idtoken " + idToken)
                    var body = {
                        token: idToken
                    };
                    axios.post('https://platform.vikit.ai:3001/create-customer', body)
                      .then(function (response) {
                        console.log(response);
                        console.log("Successful")
                        setConnected(true)
                    })
                    .catch(function (error) {
                      deleteDoc(doc(db, 'users', result.user.uid))
                      console.log("Error with calling Vikit backend : " + error);
                    });
    
                  }).catch(function(error) {
                    deleteDoc(doc(db, 'users', result.user.uid))
                    console.log(`Error getting the idToken ${error}`);
                    // Handle error
                  });
                
                })
                  .catch((error) => {
                    deleteDoc(doc(db, 'users', result.user.uid))
                    errorDisplayed=error;
                    console.log(`Error in creating customer record ${error}`)});

                } else {
                  console.log("Document already exists")
                }
              }
              )
              }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                // The AuthCredential type that was used.
                //const credential = GoogleAuthProvider.credentialFromError(error);
                console.log(error)
                // ...
              })}
          >
            <div id="loading" class="hidden">Signing up...</div>
            <span id="button-text"><div><img width="7%" src={googleLogo}></img>&nbsp;&nbsp;Sign in with Google</div></span>
          </button>
          
      </div>
      {errorDisplayed}
    </div>
    <div
      class="transition transform fixed bottom-0 inset-x-0 px-2 pb-4 sm:px-0 sm:pb-6"
    >
      <a
        href="https://github.com/Vikit-ai"
        class="flex items-center justify-between rounded-lg shadow-lg pl-6 pr-4 py-3 bg-white sm:hidden"
      >
        Backend platform of Vikit.ai

        <span
          class="flex items-center justify-center px-3 py-2 border text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
        >
          Go to GitHub

        </span>
      </a>
      <div class="hidden sm:block max-w-8xl mx-auto px-4 lg:px-6">
        <div class="py-3 pl-6 pr-3 rounded-lg bg-white shadow-lg">
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-full flex-1 flex items-center sm:w-0">
              <p class="text-white truncate">
                <strong class="font-medium text-pasha md:hidden">
                  This the backend platform&nbsp;
                </strong>
                <strong class="hidden md:inline font-medium text-pasha">
                This the backend platform&nbsp;
                </strong>
                <span class="lg:hidden text-pasha">
                  for the Vikit.ai Video AI Software Development Kit
                </span>
                <span class="hidden lg:inline text-pasha">
                  for the Vikit.ai Video AI Software Development Kit
                </span>
              </p>
            </div>
            <div class="flex-shrink-0">
              <div class="rounded-md shadow-sm">
                <a
                  href="https://github.com/Vikit-ai"
                  class="flex items-center justify-center px-4 py-2 border text-sm leading-5 font-medium rounded text-pasha bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                >
                  Go on Github Page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
  <footer class="h-24"></footer>
</div></HttpsRedirect>)

export default PlatformDisconnected;
